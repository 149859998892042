const $ = window.Bliss
const $$ = $.$

export class OverlayHandler {
  constructor(el) {
    this.el = el
    this.closeButton = $("[data-overlay-toggle]")
    this.changeButton = $("[data-overlay-change-level]", this.el)
    this.overlayMenu = $("[data-overlay-menu]", this.el)
    this.overlayMenuParent = $$("[data-overlay-menu-parent]", this.el)
    this.activeLevel = false
    this.isOpen = false
    this.overlayMenuViewer = $("[data-overlay-menu-viewer]", this.el)
    this.children = $$("[data-overlay-menu-children]")
    this.addListeners()
  }

  addListeners = () => {
    let mediaQuery = window.matchMedia("(max-width: 750px)")

    if (mediaQuery.matches) {
      this.overlayMenuParent.forEach(el => {
        if (el.getAttribute("data-overlay-has-children")) {
          el.dataset.overlayAccordion = "true"
        }
      })
    }

    window.addEventListener("resize", () => {
      if (mediaQuery.matches) {
        this.overlayMenuParent.forEach(el => {
          if (el.getAttribute("data-overlay-has-children")) {
            el.dataset.overlayAccordion = "true"
          }
        })
      } else {
        $("[data-overlay-content-level='first']")
          .querySelectorAll("[data-overlay-menu-parent]")
          .forEach(el => {
            el.dataset.overlayAccordion = "false"
          })
      }
    })

    // $('a[data-overlay-level="first"]').addEventListener('click', e => {
    //   e.preventDefault()
    //   this.overlayLevel = "first"
    //   this.closeButton.dataset.overlayToggle = "close"
    //   this.openOverlay(this.overlayLevel)
    //   this.isOpen = true
    // })
    var isIE = /MSIE|Trident/.test(window.navigator.userAgent)

    if (!isIE) {
      document.body.addEventListener("click", e => {
        const link = e.target.closest('a[data-overlay-level="first"]')

        // console.log(typeof link.dataset.overlayLevel === 'string' );

        if (link && !this.isOpen) {
          this.overlayLevel = link.dataset.overlayLevel
          e.preventDefault()
          this.openOverlay(this.overlayLevel)
          this.activeLevel = this.overlayLevel
          this.closeButton.dataset.overlayToggle = "close"
          this.isOpen = true
        } else if (
          !e.target.closest("[data-overlay-container]") &&
          this.isOpen
        ) {
          e.preventDefault()
          this.isOpen = false
          this.closeButton.dataset.overlayToggle = "open"
          this.close()
        }
      })
    } else {
      this.closeButton.addEventListener("click", e => {
        if (this.closeButton.dataset.overlayToggle == "open" && !this.isOpen) {
          e.preventDefault()
          this.activeLevel = this.overlayLevel
          this.closeButton.dataset.overlayToggle = "close"
          this.closeButton.classList.add("close")
          this.openOverlay("first")
        } else {
          e.preventDefault()
          this.closeButton.classList.remove("close")
          this.close()
        }
      })
    }

    this.overlayMenuParent.forEach(link => {
      let overlayAccordion = link.dataset.overlayAccordion

      let child = $("[data-overlay-menu-children]", link)
      let childToggle = $(".navigation--item-link", link)

      if (child && overlayAccordion === "false") {
        this.overlayMenuViewer.appendChild(child)
      }

      if (overlayAccordion === "false" && !mediaQuery.matches) {
        link.addEventListener("click", () => {
          this.children.forEach(child => child.classList.remove("active"))
          this.overlayMenuParent.forEach(child =>
            child.classList.remove("active")
          )
          if (link.classList.contains("active")) {
            child.classList.remove("active")
            link.classList.remove("active")
            this.el.classList.remove("active--token")
          } else {
            child.classList.add("active")
            link.classList.add("active")
            this.el.classList.add("active--token")
          }
          // link.style.opacity = '0.5'
          // this.overlayMenuViewer
        })
      } else {
        childToggle.addEventListener("click", () => {
          // this.children.forEach((child) => child.classList.remove("active"))
          this.overlayMenuParent.forEach(child =>
            child.classList.remove("active")
          )
          if (child) {
            if (child.classList.contains("active")) {
              child.classList.remove("active")
              link.classList.remove("active")
              this.overlayMenu.parentNode.classList.remove("active--token")
            } else {
              let childHeight = $(".navigation--children-wrapper", child)
                .offsetHeight
              this.children.forEach(child => child.classList.remove("active"))
              child.classList.add("active")
              link.classList.add("active")
              child.style.height = childHeight + "px"
              this.overlayMenu.parentNode.classList.add("active--token")
            }
          }
        })
      }
    })
  }
  //
  // appendContent = (content) => {
  //
  // }

  openOverlay = level => {
    document.body.dataset.overlay = true
    document.body.dataset.overlayLevel = level
    this.el.classList.add("active")
  }

  close = () => {
    document.body.dataset.overlay = false
    document.body.dataset.overlayLevel = null
    this.el.classList.remove("active")
    this.closeButton.dataset.overlayToggle = "open"
    this.isOpen = false
  }
}
