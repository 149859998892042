import "./main.scss"
import "cropperjs/dist/cropper.css"

import "blissfuljs"
// import "js/contentSlider"
//import 'js/smoothscroll'
import _throttle from "lodash/throttle"

import {ToggleElements} from "./js/toggleElements"
import scrollmenu from "js/scrollmenu"
import {Accordion} from "./js/accordion"
import {Navigation} from "./js/navigation"
import {Gallery} from "js/gallery"
import {TopicGallery} from "js/TopicGallery.js"
import {ProgressBar} from "./js/progressbar"
import {Letter} from "./js/letter"
import BigPicture from "bigpicture"

import ImageCropping from "./js/imageCropping"
import {OverlayHandler} from "./js/overlayHandler"

const $ = window.Bliss
const $$ = $.$

$.ready().then(function() {
  if ("NodeList" in window && !NodeList.prototype.forEach) {
    // console.info("polyfill for IE11")
    NodeList.prototype.forEach = function(callback, thisArg) {
      thisArg = thisArg || window
      for (var i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this)
      }
    }
  }

  // let viewport = $(".debug-viewport")
  // window.addEventListener("resize", () => {
  //   let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
  //   let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
  //   viewport.innerText = `${vw} x ${vh}`
  //   console.log(`${vw} x ${vh}`)
  // })

  // $$("form[data-ajax-form]").forEach(form => new AjaxForm(form))

  new ImageCropping()

  // Mobile Nav Toggle
  new Navigation()

  new ToggleElements()

  // Overlay Handler

  const overlay = $("[data-overlay-wrapper]")

  if (overlay) {
    new OverlayHandler(overlay)
  }

  const letterForm = $("[data-letter-form]")
  const letterViewer = $("[data-letter-viewer]")

  if (letterForm) {
    new Letter(letterForm, letterViewer)
  }

  let accordion = $$("[data-accordion-id]")
  if (accordion) {
    $$("[data-accordion-id]").forEach(el => new Accordion(el))
  }

  let scrolledThreshold = 120
  let scrolling = false
  let topicBreadcrumbs = $("[data-topic-breadcrumbs]")
  window.addEventListener(
    "scroll",
    _throttle(() => {
      scrolling = true

      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add("scrolled")
        document.body.classList.remove("scrolled--top")
      } else {
        document.body.classList.remove("scrolled")
        document.body.classList.add("scrolled--top")
      }

      if (topicBreadcrumbs && scrolling) {
        topicBreadcrumbs.style.opacity = 1
      }
    }, 100)
  )

  setInterval(() => {
    if (topicBreadcrumbs && scrolling) {
      scrolling = false

      if ($("body").classList.contains("scrolled--top")) {
        topicBreadcrumbs.style.opacity = 1
      } else {
        topicBreadcrumbs.style.opacity = 0
      }
    }
  }, 3000)

  let input = $$(".wrapper__number")

  input.forEach(function(e) {
    let btn_add = e.querySelector(".add"),
      btn_remove = e.querySelector(".remove"),
      inputValRaw = e.querySelector("input")

    function updateInput(v) {
      inputValRaw.value = v
    }

    inputValRaw.addEventListener("change", function() {
      let input_val = parseInt(inputValRaw.value, 10)
      if (input_val > inputValRaw.max) {
        inputValRaw.value = inputValRaw.max
      }
    })
    btn_remove.addEventListener("click", function() {
      let input_val = parseInt(inputValRaw.value, 10)
      if (input_val > inputValRaw.min) {
        input_val--
        updateInput(input_val)
      }
    })
    btn_add.addEventListener("click", function() {
      let input_val = parseInt(inputValRaw.value, 10)
      if (input_val < inputValRaw.max) {
        input_val++
        updateInput(input_val)
      }
    })
  })

  // form

  let formInput = $$(".form-fieldset > input")

  if (input) {
    window.addEventListener("change", function() {
      formInput.forEach(function(e) {
        if (!e.value == "") {
          e.classList.add("filled")
        } else {
          e.classList.remove("filled")
        }
      })
    })

    formInput.forEach(function(e) {
      e.setAttribute("tabindex", 0)
      if (!e.value == "") {
        e.classList.add("filled")
      } else {
        e.classList.remove("filled")
      }
    })
  }

  // ProgressBar

  $$("[data-progress]").forEach(
    el =>
      new ProgressBar(
        el,
        el.getAttribute("data-progress-max"),
        el.getAttribute("data-progress-current"),
        el.querySelector("[data-progress-bar]")
      )
  )
})

window.addEventListener("load", function() {
  const targetList = $$("[data-anchor]")
  if (targetList.length >= 1) {
    new scrollmenu(["[data-link]", "[data-link-breadcrumb]"])
  }

  const testimonials = $$(".testimonial-slide")
  const topicSlider = $("[data-topic-slider]")
  const sliderSubpages = $("[data-slider-subpages]")

  var isIE = /MSIE|Trident/.test(window.navigator.userAgent)

  if (!isIE) {
    const appear = document.querySelectorAll(".appear")
    let observerAnim = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          entry.target.style.animation = `${entry.target.dataset.appear} ${
            entry.target.dataset.speed
          } ${entry.target.dataset.delay} forwards ease-out`
        } else {
          // entry.target.style.animation = 'none';
        }
      })
    })

    appear.forEach(entry => {
      observerAnim.observe(entry)
    })

    const appearSlide = document.querySelectorAll(".appear-slide")
    const options = {
      threshold: 0,
      rootMargin: "-300px",
    }

    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          entry.target.dataset.autoplay = true
        } else {
          // entry.target.style.animation = 'none';
        }
      })
    }, options)

    appearSlide.forEach(entry => {
      observer.observe(entry)
    })
  }

  if (testimonials) {
    testimonials.forEach(e => {
      new Gallery(e, {
        interval: 5000,
        autoPlay: true,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
      })
    })
  }
  if (sliderSubpages) {
    const factSlider = $$(".factslider__slide", sliderSubpages)
    factSlider.forEach(e => {
      new Gallery(e, {
        interval: 10000,
        autoPlay: sliderSubpages.dataset.autoplay,
        createThumbs: true,
        endless: false,
        visibleSlides: 1,
      })
    })
  }
  if (topicSlider) {
    new TopicGallery(topicSlider, {
      interval: 5000,
      autoPlay: topicSlider.dataset.autoplay,
      createThumbs: true,
      endless: false,
      visibleSlides: 1,
    })
  }

  let charts = $$("[data-chart]")

  function openChart(el) {
    BigPicture({
      el: el.target,
      imgSrc: el.target.dataset.chartSrc,
    })
  }
  if (charts) {
    charts.forEach(chart => {
      chart.addEventListener("click", el => {
        el.preventDefault()
        openChart(el)
      })
    })
  }
})
