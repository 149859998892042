/* Global $ */

const $ = window.Bliss
const $$ = $.$

import {getCookie} from "./utils"

function lowerFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

let today = new Date()
let expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000) // plus 30 days
function setCookie(name, value) {
  document.cookie =
    name + "=" + value + "; path=/; expires=" + expiry.toGMTString()
}

export class Letter {
  // value
  constructor(el, viewer) {
    this.el = el
    this.viewer = viewer
    this.thurLabels = $$("[data-letter-thur] input", this.el)
    this.thur = $("[data-letter-thur]", this.el)
    this.conceptLabels = $$("[data-letter-concept] input", this.el)
    this.informations = $("[data-letter-informations]", this.el)
    this.informationsLabels = $$("[data-letter-informations] input", this.el)
    this.concept = $("[data-letter-concept]", this.el)
    this.viewerThurLabels = $("[data-viewer-thur]", this.viewer)
    this.viewerConceptLabels = $("[data-viewer-concept]", this.viewer)
    this.informationsViewer = $("[data-viewer-info]", this.viewer)
    this.placeViewer = $("[data-viewer-place]", this.viewer)

    this.setListener()
    this.preFill()
  }

  preFill = () => {
    this.informationsLabels.forEach((e, index) => {
      if (getCookie(e.id)) {
        e.value = getCookie(e.id)
      }
      this.informationsViewer.appendChild(
        $.create({
          tag: "span",
          textContent: getCookie(e.id),
          class: `letter__element--info letter__element--info-${index}`,
        })
      )
    })

    if (getCookie("id_place")) {
      this.placeViewer.appendChild(
        $.create({
          tag: "span",
          textContent: getCookie("id_place") + ", ",
          class: `letter__element--place`,
        })
      )
    } else {
      this.placeViewer.appendChild(
        $.create({
          tag: "span",
          textContent: "",
          class: ``,
        })
      )
    }

    this.conceptLabels.forEach((e, index) => {
      if (getCookie(e.id)) {
        e.checked = getCookie(e.id)
        if (getCookie(e.id).length > 0) {
          this.viewerConceptLabels.appendChild(
            $.create({
              tag: "span",
              textContent: getCookie(e.id),
              class: `letter__element--concept letter__element--concept-${index}`,
            })
          )
        }
      }
    })
    this.thurLabels.forEach((e, index) => {
      if (getCookie(e.id)) {
        $("[data-viewer-capital-case]").innerText = "D"
        e.checked = getCookie(e.id)
        if (getCookie(e.id).length > 0) {
          this.viewerThurLabels.appendChild(
            $.create({
              tag: "span",
              textContent: getCookie(e.id),
              class: `letter__element--thur letter__element--thur-${index} letter__element--thur-${
                e.value
              }`,
            })
          )
        }
      }
    })
  }

  setListener = () => {
    this.thur.addEventListener("change", e => {
      if (e.target.checked) {
        setCookie(
          e.target.id,
          lowerFirstLetter(e.target.dataset.text.toString())
        )
      } else {
        setCookie(e.target.id, "")
      }
      this.thurLabelsChecked = [].filter
        .call(this.thurLabels, c => c.checked)
        .map(c => c)
      // .map(c => c.labels[0].innerText)

      this.updateThurViewer(this.thurLabelsChecked)
    })

    this.concept.addEventListener("change", e => {
      if (e.target.checked) {
        setCookie(e.target.id, e.target.dataset.text)
      } else {
        setCookie(e.target.id, "")
      }
      this.conceptLabelsChecked = [].filter
        .call(this.conceptLabels, c => c.checked)
        .map(c => c)
      // .map(c => c.closest("li").dataset.text)
      this.updateConceptViewer(this.conceptLabelsChecked)
    })

    this.informations.addEventListener("change", e => {
      setCookie(e.target.id, e.target.value)

      this.informationsLabelsArray = [].filter
        .call(this.informationsLabels, e => e.value.length > 0)
        .map(e => e)
      this.updateInformationViewer(this.informationsLabelsArray)
    })
  }

  updateInformationViewer = (values, id) => {
    let allThurLabels = $$(".letter__element--info")
    let thurPalce = $(".letter__element--place")

    if (allThurLabels) {
      allThurLabels.forEach(element => {
        this.informationsViewer.removeChild(element)
      })

      if (thurPalce) {
        this.placeViewer.removeChild(thurPalce)
      }
    }

    values.forEach((value, index) => {
      this.informationsViewer.appendChild(
        $.create({
          tag: "span",
          textContent: value.value,
          class: `letter__element--info letter__element--${
            value.id
          } letter__element--info-${index} `,
          id: id,
        })
      )
      if (value.id === "id_place") {
        this.placeViewer.appendChild(
          $.create({
            tag: "span",
            textContent: value.value + ", ",
            class: `letter__element--place`,
          })
        )
      }
    })
  }

  updateThurViewer = thurLabels => {
    let allThurLabels = $$(".letter__element--thur")

    if (allThurLabels) {
      $("[data-viewer-capital-case]").innerText = "d"
      allThurLabels.forEach(element => {
        this.viewerThurLabels.removeChild(element)
      })
    }

    thurLabels.forEach((element, index) => {
      $("[data-viewer-capital-case]").innerText = "D"

      this.viewerThurLabels.appendChild(
        $.create({
          tag: "span",
          textContent: lowerFirstLetter(element.dataset.text.toString()),
          class: `letter__element--thur letter__element--thur-${index} letter__element--thur-${
            element.value
          }`,
        })
      )
    })
  }

  updateConceptViewer = conceptLabels => {
    let allConceptLabels = $$(".letter__element--concept")

    if (allConceptLabels) {
      allConceptLabels.forEach(element => {
        this.viewerConceptLabels.removeChild(element)
      })
    }

    conceptLabels.forEach((element, index) => {
      this.viewerConceptLabels.appendChild(
        $.create({
          tag: "span",
          textContent: element.dataset.text,
          class: `letter__element--concept letter__element--concept-${index}`,
        })
      )
    })
  }
}
