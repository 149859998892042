/*eslint no-console: ["error", { allow: ["warn", "error"] }] */
import Cropper from "cropperjs"
import fetch from "isomorphic-fetch"

if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
    value: function(callback, type, quality) {
      var canvas = this
      setTimeout(function() {
        var binStr = atob(canvas.toDataURL(type, quality).split(",")[1]),
          len = binStr.length,
          arr = new Uint8Array(len)

        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i)
        }

        callback(new Blob([arr], {type: type || "image/png"}))
      })
    },
  })
}

export default class ImageCropping {
  constructor() {
    this.form = document.querySelector("form.form--testimonial")

    if (this.form) {
      this.init()
    }
  }

  init = () => {
    this.cropperEl = this.form.querySelector(".image-cropping")
    this.img = this.cropperEl.querySelector("img")
    this.fileInput = this.form.querySelector('input[type="file"]')
    this.fileInput.addEventListener("change", this.setFile)
    this.form.onsubmit = this.submit
    this.imageCropped = false

    this.currentPortraitLink = this.form.querySelector(
      ".widget--clearablefileinput a"
    )
    if (this.currentPortraitLink) {
      let portraitCell = this.currentPortraitLink.parentNode
      portraitCell.classList.add("image-cropping__portrait-cell")
      let thumb = document.createElement("img")
      thumb.src = this.currentPortraitLink.href
      portraitCell.insertBefore(thumb, portraitCell.firstChild.nextSibling)
    }
  }

  setFile = () => {
    let files = this.fileInput.files

    if (files.length >= 1) {
      let file = files[0]
      var imageType = /^image\//

      if (!imageType.test(file.type)) {
        // console.error('not a valid image file')
        return
      }

      this.cropper = new Cropper(this.img, {
        viewMode: 2,
        aspectRatio: 1 / 1,
        crop: (/* e */) => {
          // console.log(e.detail.x)
        },
        guides: false,
      })

      var reader = new FileReader()
      reader.onload = e => {
        this.cropperEl.classList.add("image-cropping--show")
        this.cropper.destroy()
        let cropperCont = this.cropperEl.querySelector(".cropper-container")
        cropperCont && cropperCont.parentNode.removeChild(cropperCont)
        this.cropper.replace(e.target.result)
        this.imageCropped = true
      }

      reader.readAsDataURL(file)
    }
  }

  getCroppedImage = () => {
    return new Promise(resolve => {
      if (this.imageCropped) {
        let croppedImage = this.cropper.getCroppedCanvas({
          width: 630,
          height: 630,
        })
        croppedImage.toBlob(resolve, "image/jpeg")
      } else {
        // resolve immediately
        resolve()
      }
    })
  }

  submit = e => {
    e.preventDefault()
    // console.log('submit()')

    let self = this

    // prettier-ignore
    this.getCroppedImage()
      .then(blob => {
        // it could have been so easy:
        // let formData = new FormData(this.form)
        // but Safari does only know formData.append()
        // therefore we need to loop over all form elements

        let formData = new FormData()

        for (let i = 0; i < this.form.elements.length; i++) {
          let formElement = this.form.elements[i]
          if (formElement.name != 'portrait' || !blob) {
            formData.append(formElement.name, formElement.value)
          } else {
            formData.append('portrait', blob, 'portrait.jpg')
          }
        }

        return formData
      })
      .then(formData => {
        return fetch(this.form.action, {
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'X-CSRFToken': document.cookie.match(/csrftoken=(\w+?)\b/)[1],
          },
          body: formData,
        })
      })
      .then(response => {
        // when 202 we have success
        if (response.status == 201) {
          return response.json()
        } else {
          return response.text()
        }
      })
      .then(data => {
        // either a JSON object is returned or a HTML string
        if (typeof data == 'object' && data.next) {
          window.location.href = data.next
        } else {
          // replace the <form> tag with the new one
          let responseDoc = document.implementation.createHTMLDocument(
            'response'
          )
          responseDoc.documentElement.innerHTML = data

          let newForm = responseDoc.querySelector('form.form--testimonial')
          self.form.parentNode.replaceChild(newForm, self.form)
          self.form = newForm
          self.init()

          let errorneousField = self.form.querySelector('.error input')
          if (errorneousField) {
            window.location.hash = errorneousField.id
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }
}
