export const getCookie = name => {
  const cookies = document.cookie
    .split("; ")
    .reduce((cookiesObject, cookieString) => {
      const cookie = cookieString.split("=")

      cookiesObject[cookie[0]] = cookie[1]

      return cookiesObject
    }, {})

  return cookies[name]
}
